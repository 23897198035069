import { motion } from "framer-motion";
import CodesipImg from "../static/images/codesip.jpeg";
import BFIImage from "../static/images/builtforimpact.png";
import DraftileImage from "../static/images/draftile.png";
import GMTVImage from "../static/images/GMTV.png";
import WeatherImage from "../static/images/weather.png";
import NodeBuilderImage from "../static/images/nodebuilder.png";
import AITutorImage from "../static/images/aitutorfinal.png";
import { Link } from "react-router-dom";

const Projects = ({ flipTransition }) => {

    var projectData = [
        {
            "image": AITutorImage,
            "name": "AI Tutor",
            "desc": "Want to lean AI and ML but don't know where to start? AI Tutor can provide you with personalized learning plan with best resources.",
            "desc2": "The project is still in development.",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs"],
            "links": {
                "link": null,
                "doc": null,
                "github": "https://github.com/Darshan-SD/capstone.git"
            }
        },
        {
            "image": NodeBuilderImage,
            "name": "Xenon",
            "desc": "Xenon is a website backend builder that allows you to connect nodes and write what they should do. It is powered by AI and can generate code for you.",
            "desc2": "The project is still in development.",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs"],
            "links": {
                "link": null,
                "doc": null,
                "github": null
            }
        },
        {
            "image": CodesipImg,
            "name": "Codesip",
            "desc": "It is a platform where you ask your imagination of web design and it will generate code and live preview for you.",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs"],
            "links": {
                "link": "https://codesip.tech",
                "doc": null,
                "github": null
            }
        },
        {
            "image": BFIImage,
            "name": "Built For Impact",
            "desc": "A platform for the students and by the students to help them in their career. It is a platform where students can find resources, projects, and events to enhance their skills.",
            "technology": ["Wordpress"],
            "links": {
                "link": "https://app.builtforimpact.ca",
                "doc": null,
                "github": null
            }
        },
        {
            "image": DraftileImage,
            "name": "Draftile",
            "desc": "This is where you can find wallpapers for your desktop and mobile. It is a library of my imaginations.",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs", "Bootstrap"],
            "links": {
                "link": "https://dwallpapers.netlify.app",
                "doc": null,
                "github": null
            }
        },
        {
            "image": GMTVImage,
            "name": "GMTV",
            "desc": "It allows you to track Movies & TV Shows you are watching. Just enter name and which season you watched. This web app uses TMDB to fetch all the movie and show related information.",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs"],
            "links": {
                "link": null,
                "doc": null,
                "github": null
            }
        },
        {
            "image": WeatherImage,
            "name": "Weatherly",
            "desc": "Too lazy to even look out of the window? No problem, weatherly got you. Simple weather app that shows the weather of your entered city.",
            "technology": ["MongoDB", "Expressjs", "Reactjs", "Nodejs"],
            "links": {
                "link": null,
                "doc": null,
                "github": null
            }
        },
    ];
    return (
        <motion.div
            variants={flipTransition}
            initial="hidden"
            animate="show"
        >

            <h2 className="heading">Work</h2>
            <div className="projects">
                {
                    projectData.map((project) => {
                        return (
                            <div className="project-con">
                                <div className="project-img-con" style={{ backgroundImage: `url(${project.image})` }}></div>
                                <h3 className="project-name">{project.name}</h3>
                                <h4 className="project-desc">{project.desc}</h4>
                                {project.desc2 ? <h4 className="project-desc">{project.desc2}</h4> : ""}
                                <div className="project-links">
                                    {project.links.link ?
                                        <Link to={project.links.link} target="_blank">
                                            <i className="fa-solid fa-link"></i>
                                        </Link>
                                        : ""}
                                    {project.links.doc ?
                                        <Link to={project.links.doc} target="_blank">
                                            <i className="fa-solid fa-file"></i>
                                        </Link>
                                        : ""}
                                    {project.links.github ?
                                        <Link to={project.links.github} target="_blank">
                                            <i className="fa-brands fa-github"></i>
                                        </Link>
                                        : ""}
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <h2 className="heading">Open-Source Contribution</h2>
            <div className="github-con">
                <a href="https://github-readme-stats.vercel.app/api?username=Darshan-SD&theme=holi" className="github-link">
                    <img
                        src="https://github-readme-stats.vercel.app/api?username=Darshan-SD&theme=holi&show_icons=true&rank_icon=github&custom_title=Github Stats"
                        alt="@Darshan's Holopin board"
                    />
                </a>
            </div>

            <h2 className="heading">Holopin Badges</h2>
            <div className="holopin-con">
                <p className="holopin-desc">Every year, DigitalOcean hosts Hacktoberfest to support open-source projects. These are the badges I earned while contributing.</p>
                <a href="https://holopin.io/@darshansd" className="holopin-link">
                    <img
                        src="https://holopin.me/darshansd"
                        alt="@Darshan's Holopin board"
                    />
                </a>
            </div>
        </motion.div>
    )
}

export default Projects;
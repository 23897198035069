import { motion } from "framer-motion";

const Experience = ({ flipTransition }) => {
    const experiences = [
        {
            title: "Events Lead",
            year: "Present",
            company: "GDG on Campus Northeastern University Toronto",
            companyLink: "https://gdg.community.dev/gdg-on-campus-northeastern-university-toronto-toronto-canada/",
            description: [
                "Organizing events and workshops for the community to learn and grow together.",
                "Collaborating with other organizations to bring the best experience for the community."
            ]
        },
        {
            title: "Web App Developer",
            year: "2024-Present",
            company: "Built For Impact",
            companyLink: "https://app.builtforimpact.ca",
            description: [
                `Working with team to develop and design the complex architecture of the Built For Impact with motto "For the Student, By the Student" in mind.`,
                "Developing and refining the company website with Wordpress and Elementor."
            ]
        },
        {
            title: "Software Developer Intern",
            year: "2023",
            company: "BrainyBeam Technologies",
            description: [
                "Optimized application programming interfaces to reduce loading time, enhancing overall system performance and user satisfaction.",
                "Collaborated closely with team members in the development of front-end components using ReactJS, fostering efficient teamwork and delivering high-quality user interfaces."
            ]
        },
        {
            title: "NodeJS Developer Intern",
            year: "2022",
            company: "FRSHR Technologies",
            description: [
                `Accomplished the development of a karaoke website for Radio Mirchi India, facilitating successful deployment, by employing a combination of frontend and backend technologies.`,
                "Maintained code quality and collaboration standards by actively monitoring GitHub branches and pull requests, resulting in reduced bugs, and facilitating smooth project progression."
            ]
        }
    ];


    return (
        <motion.div
            variants={flipTransition}
            initial="hidden"
            animate="show"
        >
            {/* Education */}
            <h2 className="heading">Education</h2>
            <div className="universities">
                <div className="university">
                    <div className="exp-name-year">
                        <h3 className="exp-name">Master of Professional Studies in Informatics</h3>
                        <h5 className="exp-year">2023-Present</h5>
                    </div>
                    <h4 className="exp-company">Northeastern University, Toronto</h4>
                </div>
                <div className="university">
                    <div className="exp-name-year">
                        <h3 className="exp-name">Bachelor of Engineering in Information Technology</h3>
                        <h5 className="exp-year">2019-2023</h5>
                    </div>
                    <h4 className="exp-company">G H Patel College of Engineering & Technology, Vidyanagar</h4>
                </div>
            </div>

            {/* Experience */}
            <h2 className="heading">Experience</h2>
            <div className="experiences">
                {experiences.map((exp, index) => (
                    <div key={index} className="experience-con">
                        <div className="exp-name-year">
                            <h3 className="exp-name">{exp.title}</h3>
                            <h5 className="exp-year">{exp.year}</h5>
                        </div>
                        <h4 className="exp-company">{
                            exp.companyLink ?
                                <a href={exp.companyLink} target="_blank" rel="noopener noreferrer">
                                    {exp.company}
                                </a>
                                : exp.company
                            }</h4>
                        <ul className="exp-desc">
                            {exp.description.map((desc, idx) => (
                                <li key={idx}>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </motion.div>
    )
}

export default Experience;
import { motion } from "framer-motion";
import MongoDB from "../static/images/mongoDB.svg";
import Express from "../static/images/express.svg";
import ReactJS from "../static/images/react.svg";
import Node from "../static/images/nodejs.svg";

import Django from "../static/images/django.svg";
import Docker from "../static/images/docker.svg";
import Dotnet from "../static/images/dotnet.svg";
import Git from "../static/images/git.svg";
import Kubernetes from "../static/images/kubernetes.svg";
import SQL from "../static/images/sql.svg";


const About = ({ flipTransition }) => {
    return (
        <motion.div
            variants={flipTransition}
            initial="hidden"
            animate="show"
            className="about-con"
        >
            <h2 className="heading">About</h2>
            <p className="about-desc">
            Driving innovation in Web 2.0 and empowering businesses with cutting-edge SaaS solutions for over five years. Now exploring the transformative realms of Artificial Intelligence and Cybersecurity. Beyond the digital world, I channel my creativity through photography and videography.
            </p>
            <h2 className="heading">Developing with</h2>
            <div className="stack-con">
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={MongoDB} alt="" className="stack-icon mongodb" />
                    </div>
                    <h4 className="stack-title">MongoDB</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={Express} alt="" className="stack-icon" />
                    </div>
                    <h4 className="stack-title">ExpressJS</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={ReactJS} alt="" className="stack-icon react" />
                    </div>
                    <h4 className="stack-title">ReactJS</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={Node} alt="" className="stack-icon" />
                    </div>
                    <h4 className="stack-title">NodeJS</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={Django} alt="" className="stack-icon react" />
                    </div>
                    <h4 className="stack-title">Django</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={Dotnet} alt="" className="stack-icon" />
                    </div>
                    <h4 className="stack-title">Dotnet</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={Git} alt="" className="stack-icon" />
                    </div>
                    <h4 className="stack-title">GIT</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={Docker} alt="" className="stack-icon" />
                    </div>
                    <h4 className="stack-title">Docker</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={Kubernetes} alt="" className="stack-icon" />
                    </div>
                    <h4 className="stack-title">Kubernetes</h4>
                </div>
                <div className="stack-element">
                    <div className="stack-icon-con">
                        <img src={SQL} alt="" className="stack-icon" />
                    </div>
                    <h4 className="stack-title">SQL</h4>
                </div>
            </div>
        </motion.div>
    )
}

export default About;